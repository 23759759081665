<template>
    <div>
        <div class="
            align-items-center
            border-grey
            qu-fc-color
            curser-pointer
        " v-b-modal.add-questions-modalv-b-modal.gpt-questions-modal>
            <span class="text-nowrap qu-margin-left-10">Import GPT Response</span>
        </div>
        <b-modal id="gpt-questions-modal" hide-footer size="lg" centered title="Add GPT Response" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                    error }}</b-alert>
                <a href="https://chatgpt.com/" target="_blank">Click to open Chat GPT</a>
                <br />
                Please add this line at the end of your GPT prompt:
                <br />
                <strong ref="output_format">
                    Output:
                    Start with the question number followed by the question text, without using labels like "Question
                    1:" or "#".
                    Start description with text description and colon like Description:
                    List the options with alphabetical bullets (a, b, c, d). Do not use a hyphen or extra space before
                    each bullet. Place "(correct)" next to the correct choice.
                    <feather-icon @click="copyOutputFormat" icon="CopyIcon" size="17"
                        class="qu-icon-grey curser-pointer" />
                </strong>
                <textarea class="mt-1" style="width: 100%; height: 400px" v-model="questionText"></textarea>
                <div class="modal-footer">
                    <b-button :disabled="!(questionText.length > 0)" @click="$emit('createQuestionJson', questionText)"
                        variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormSelect,
    BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        BFormSelect,
        BFormCheckbox,
    },
    data() {
        return {
            loading: false,
            errorMsg: "",
            questionText: "",
            alertMessageToast: ''
        };
    },
    computed: {
        hasError() {
            return this.error != "";
        },
    },
    mounted() {
        this.errorMsg = this.error;
    },
    methods: {
        copyOutputFormat() {
            const textToCopy = 'Output: Start with the question number followed by the question text, without using labels like "Question 1:" or "#". Start description with text description and colon like Description: List the options with alphabetical bullets (a, b, c, d). Do not use a hyphen or extra space before each bullet. Place "(correct)" next to the correct choice.';
            navigator.clipboard.writeText(textToCopy)
            this.alertMessageToast = 'Copied!';
            this.showToast("success");
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.$emit("resetGptModel")
        },
        resetModal() {
            this.$emit("resetGptModel")
            this.questionText = "";
        },
    },
    props: ["error"],
};
</script>
<style>
#add-questions-modal .card-body {
    padding: 0px;
}

.curser-pointer {
    cursor: pointer;
}
</style>
