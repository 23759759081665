<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
        <!-- Search -->
        <b-col cols="12" md="6" v-if="addQuestionTypeHideButton == true">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input @input="questionTypeFilter" v-model="searchQuery" class="d-inline-block mr-1"
              placeholder="Search..." />
            <b-button variant="primary" v-b-modal.modal-xl @click="JsontoStringify">
              <span class="text-nowrap">Add Question Type</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-overlay :show="show" rounded="sm">
      <b-table-simple v-if="addQuestionTypeHideButton == true" hover caption-top responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th>No.</b-th>
            <b-th>Question Type</b-th>
            <b-th>Thumb</b-th>
            <b-th>Grades</b-th>
            <b-th>Topics</b-th>
            <b-th>Levels</b-th>
            <b-th>Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(questiontype, index) in table_data">
            <b-tr :key="index">
              <b-td>
                {{ index + 1 }}
              </b-td>
              <b-td> {{ questiontype.structure.name }}
              </b-td>
              <b-td>
                <span v-if="questiontype.data">
                  <span v-for="(questionData, ind) in questiontype.data" :key="ind + '1'">
                    <img @click="editThumb(questionData, ind, questiontype._id)" style="width: 50px; height: 50px"
                      :src="questionData.img" alt="question type thumb" />
                  </span>
                </span>
                <span @click="saveQuestionTypeId(questiontype._id)">
                  <feather-icon v-b-modal.upload-img-model style="
                      margin: 2%;
                      border: 1px solid black;
                      border-radius: 4px;
                    " icon="PlusIcon" size="18" />
                </span>
              </b-td>
              <b-td>
                <span v-if="questiontype.total_grades !== undefined">
                  <b-link v-if="!isclicked" @click="showTopicList(questiontype)">{{ questiontype.total_grades
                    }}</b-link>
                  <span v-else>{{ questiontype.total_grades }}</span>
                </span>
                <b-link v-else @click="showTopicList(questiontype)">N/A</b-link>
              </b-td>
              <b-td>
                <span v-if="questiontype.total_topics !== undefined">
                  <b-link v-if="!isclicked" @click="showTopicList(questiontype)">{{ questiontype.total_topics
                    }}</b-link>
                  <span v-else>{{ questiontype.total_topics }}</span>
                </span>
                <b-link v-else @click="showTopicList(questiontype)">N/A</b-link>
              </b-td>
              <b-td>
                <span v-if="questiontype.total_levels !== undefined">
                  <b-link v-if="!isclicked" @click="showTopicList(questiontype)">{{ questiontype.total_levels
                    }}</b-link>
                  <span v-else>{{ questiontype.total_levels }}</span>
                </span>
                <b-link v-else @click="showTopicList(questiontype)">N/A</b-link>
              </b-td>

              <b-td>
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item v-b-modal.modal-edit-questionType @click="editquestiontype(index, questiontype._id)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deletequestiontype(questiontype._id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-overlay>
    <b-modal id="upload-edit-model" hide-footer size="md" centered title="Edit Thumb">
      <div class="modal_content">
        <div class="d-flex w-100">
          <b-form-group class="w-100" label="Image Title">
            <b-form-input v-model="imageTitle" placeholder="Enter image title.." type="text" />
          </b-form-group>
        </div>
        <div style="display: flex; flex-direction: row; overflow: auto">
          <span style="
              /* border: 1px solid black; */
              width: 70px;
              height: auto;
              position: relative;
            " v-if="multi_img != ''">
            <div class="image-container" @click="showFullScreenModal">
              <img class="preview-image" style="width: 120px; height: 70px" :src="multi_img" alt="" />
              <div class="icon-overlay">
                <img src="zoom-in.svg" alt="Icon" class="custom-icon" />
              </div>
              <b-modal v-model="showFullScreen" size="xl">
                <img :src="multi_img" alt="Fullscreen" style="width: 100%; height: 100%;" />
              </b-modal>
            </div>
          </span>
        </div>
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" accept="image/x-png,image/gif,image/jpeg" class="d-none"
            @input="ImageRendererItems" />
          <span class="d-none d-sm-inline">Upload Image</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <div class="d-flex w-100 mt-1">
          <b-form-group class="w-100" label="Video link">
            <b-form-input v-model="videoLink" placeholder="Please enter video url" type="url" />
          </b-form-group>
        </div>
        <div class="w-100">
          <b-form-group label="Description" label-for="Description">
            <b-form-textarea v-model="descriptionBox" id="Description" />
          </b-form-group>
        </div>

        <div class="modal-footer">
          <button type="button" :disabled="imageTitlebutton" @click="submitthumbEdit" class="btn btn-primary">
            Save
          </button>
          <button type="button" @click="closeImageUploadEdit" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="upload-img-model" hide-footer size="md" centered title="Add Thumb">
      <div class="modal_content">
        <div class="d-flex w-100">
          <b-form-group class="w-100" label="Image Title">
            <b-form-input v-model="imageTitle" placeholder="Enter image title.." type="text" />
          </b-form-group>
        </div>
        <div style="display: flex; flex-direction: row; overflow: auto">
          <span style="
              /* border: 1px solid black; */
              width: 70px;
              height: auto;
              position: relative;
            " v-if="multi_img != ''">
            <img style="width: 120px; height: 70px" :src="multi_img" alt="" />
            <div class="view-text">View</div>
          </span>
        </div>
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" accept="image/x-png,image/gif,image/jpeg" class="d-none"
            @input="ImageRendererItems" />
          <span class="d-none d-sm-inline">Upload Image</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <div class="d-flex w-100 mt-1">
          <b-form-group class="w-100" label="Video link">
            <b-form-input v-model="videoLink" placeholder="Please enter video url" type="url" />
          </b-form-group>
        </div>
        <div class="w-100">
          <b-form-group label="Description" label-for="Description">
            <b-form-textarea v-model="descriptionBox" id="Description" />
          </b-form-group>
        </div>

        <div class="modal-footer">
          <button type="button" :disabled="imageTitlebutton" @click="submitImageUploadmodel" class="btn btn-primary">
            Save
          </button>
          <button type="button" @click="closeImageUploadmodel" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-edit-questionType" hide-footer size="xl" centered title="Edit Question Type">
      <div class="modal_content">
        <div class="d-flex w-100">
          <div class="w-50">
            <textarea v-model="editNewQuestionType" style="
                width: 100%;
                height: 500px;
                background: #1e1e1e;
                color: #ce723b;
              "></textarea>
            <b-button @click="changetoschema">change to schema</b-button>
          </div>
          <div class="w-50">
            <textarea v-model="editNewQuestionSigma" style="
                width: 100%;
                height: 500px;
                background: #1e1e1e;
                color: #ce723b;
              "></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" @click="updateQuestionType()" class="btn btn-primary">
            Update
          </button>
          <button type="button" @click="closeEditModel" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-xl" hide-footer size="xl" centered title="Question Type">
      <div class="modal_content">
        <div class="d-flex w-100">
          <div class="w-50">
            <textarea v-model="addNewQuestionType" style="
                width: 100%;
                height: 500px;
                background: #1e1e1e;
                color: #ce723b;
              "></textarea>
            <b-button @click="changetosigma">change to schema</b-button>
          </div>
          <div class="w-50">
            <textarea v-model="addNewQuestionSigma" style="
                width: 100%;
                height: 500px;
                background: #1e1e1e;
                color: #ce723b;
              "></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" @click="submitjsonmodel" class="btn btn-primary">
            Add Question type
          </button>
          <button type="button" @click="closemodel" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="details-modal" ref="detailsModal" :title="'Name: ' + detailsTittle">
      <template v-if="topicDetails && topicDetails.length > 0">
        <div v-for="(classItem, classIndex) in topicDetails" :key="classIndex">
          <h2 class="text-center">Grade: {{ classItem.className }}</h2>
          <div v-for="(subject, subjectId) in classItem.subjects" :key="subjectId">
            <h3>Subject: {{ subject.subjectName }}</h3>
            <div v-for="(category, categoryId) in subject.categories" :key="categoryId">
              <h6>Topic: {{ category.categoryName }}</h6>
              <ul>
                <li v-for="(level, levelId) in category.levels" :key="levelId">
                  Level: {{ level.levelName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <p>No records found.</p>
      </template>
    </b-modal>


  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BFormTextarea,
  BImg,
  BTh,
  BTd,
  BTbody,
  BOverlay,
  BTfoot,
} from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import S3 from "aws-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    ToastificationContent,
    BRow,
    BOverlay,
    BImg,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BFormGroup,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      notificationMessage: "",
      structure: {},
      schema: {},
      topicDetails: [],
      detailsTittle: "",
      editNewQuestionSigma: {},
      editNewQuestionType: {},
      addNewQuestionSigma: {},
      videoLink: "",
      imageTitle: "",
      multi_img: "",
      descriptionBox: "",
      addNewQuestionType: {
        name: "",
        title: "",
        backgroundType: "",
        backgroundImage: "",
        backgroundColor: "",
        optionimage: [
          {
            value: "",
            isCorrect: "",
          },
          {
            value: "",
            isCorrect: "",
          },
        ],
        option: [
          {
            img1: "",
          },
          {
            img2: "",
          },
        ],
      },
      show: false,
      searchQuery: "",
      table_data: {},
      editquestiondata: {},
      addQuestionTypeHideButton: false,
      questionTypeImg: "",
      typeId: "",
      table_: "",
      editIndex: "",
      showFullScreen: false,
      isclicked: false,
    };
  },
  created() {
    this.gettabledata();
  },
  computed: {
    imageTitlebutton() {
      return !(
        this.imageTitle.length > 0 &&
        this.multi_img.length > 0 &&
        this.videoLink.length > 0 &&
        this.descriptionBox.length > 0
      );
    },
  },

  methods: {
    showFullScreenModal() {
      this.showFullScreen = true;
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },
    showTopicList(questiontype) {
      this.isclicked = true;
      axios.post(process.env.VUE_APP_API_URL + "/question-type/topic-details", {
        questionTypeId: questiontype._id
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_t"),
        },
      })
        .then(response => {
          this.topicDetails = response.data.topicDetails;
          this.detailsTittle = questiontype.structure.name;
          // this.gettabledata();
          this.$bvModal.show('details-modal');
          this.isclicked = false;
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            this.notificationMessage = 'Request timed out. Please try again later.';
          } else if (error.response) {
            this.notificationMessage = error.response.data.message;
          } else {
            this.notificationMessage = 'An error occurred. Please try again later.';
          }
          this.showToasted("danger");
        });

    },
    submitthumbEdit() {
      let ImageObject = {
        id: this.typeId,
        index: this.editIndex,
        data: {
          img: this.multi_img,
          imgTitle: this.imageTitle,
          video: this.videoLink,
          description: this.descriptionBox,
        },
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/question-type/thumb/edit",
          ImageObject,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Thumb edit successfully") {
            this.gettabledata();
            this.alertMessageToast = response.data.message;
            this.showToast("success");
          } else {
            this.alertMessageToast = "Thumb not edit try again.";
            this.showToast("danger");
          }
        });
      (this.editIndex = ""), (this.typeId = "");
      this.multi_img = "";
      this.imageTitle = "";
      this.videoLink = "";
      this.descriptionBox = "";
      this.$root.$emit("bv::hide::modal", "upload-edit-model");
    },
    closeImageUploadEdit() {
      this.$root.$emit("bv::hide::modal", "upload-edit-model");
    },
    editThumb(data, index, Id) {
      this.typeId = Id;
      this.editIndex = index;
      this.multi_img = data.img;
      this.imageTitle = data.imgTitle;
      this.videoLink = data.video;
      this.descriptionBox = data.description;
      this.$root.$emit("bv::show::modal", "upload-edit-model");
    },
    questionTypeFilter() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          let finaldata = this.table_.filter((item) => {
            if (item.structure) {
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every((v) => item.structure.name.toLowerCase().includes(v));
            }
          });
          this.table_data = finaldata;
          this.show = false;
        } else {
          this.table_data = this.table_;
          this.show = false;
        }
      }, 1000);
    },
    submitImageUploadmodel() {
      let ImageObject = {
        id: this.typeId,
        img: this.multi_img,
        imgTitle: this.imageTitle,
        video: this.videoLink,
        description: this.descriptionBox,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/question-type/thumb/add",
          ImageObject,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Thumb added successfully") {
            this.gettabledata();
            this.alertMessageToast = response.data.message;
            this.showToast("success");
          } else {
            this.alertMessageToast = "Thumb not added try again.";
            this.showToast("danger");
          }
        });
      (this.typeId = ""),
        (this.multi_img = ""),
        (this.imageTitle = ""),
        (this.videoLink = ""),
        (this.descriptionBox = ""),
        this.$root.$emit("bv::hide::modal", "upload-img-model");
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    saveQuestionTypeId(id) {
      this.typeId = "";
      this.editIndex = "";
      this.multi_img = "";
      this.imageTitle = "";
      this.videoLink = "";
      this.descriptionBox = "";
      this.typeId = id;
    },
    deleteimage(index) {
      this.multi_img.splice(index, 1);
    },
    deletequestiontype(deleteindex) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const a = {
            id: deleteindex,
          };
          axios
            .post(process.env.VUE_APP_API_URL + "/question-type/delete", a, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            })
            .then((response) => {
              this.gettabledata();
            });
        }
      });
    },
    changetoschema() {
      var a = JSON.parse(this.editNewQuestionType.replace(/null/g, '""'));
      var b = this.editNewQuestionSigma;
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var nestedobject = {};
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          arrfinal = {};
          var newkey = "fieldname";
          arrfinal[newkey] = key;
          arrfinal["fieldType"] = "";
          arrfinal["show"] = true;

          arrfinal["values"] = [];
          arrfinal["nested"] = [];
          if (typeof value == "string") {
            arrfinal["data"] = "";
          }
          if (typeof value == "object") {
            if (value.length == 0) {
              arrfinal["data"] = [];
            } else {
              for (let x = 0; x < value.length; x++) {
                nestedobject = {};
                if (Object.keys(value[x]).length == 1) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    value[x]
                  )) {
                    createobject = {};
                    createobject["fieldname"] = nestedkey;
                    createobject["fieldType"] = "";
                    createobject["show"] = true;
                    createobject["values"] = [];
                    createobject["data"] = "";
                    arrfinal["nested"].push(createobject);
                  }
                }
                if (Object.keys(value[x]).length > 1) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    value[x]
                  )) {
                    createobject = {};
                    createobject["fieldname"] = nestedkey;
                    createobject["fieldType"] = "";
                    createobject["show"] = true;
                    createobject["values"] = [];
                    createobject["data"] = "";
                    nestedobject[nestedkey] = createobject;
                  }

                  arrfinal["nested"].push(nestedobject);
                }
              }
            }
          }
          pushallobject.push(arrfinal);
        }
      }

      b = pushallobject;
      this.editNewQuestionSigma = b;
      this.editNewQuestionSigma = JSON.stringify(
        this.editNewQuestionSigma,
        null,
        2
      );
    },
    editquestiontype(questionTypeIndex, index) {
      this.editquestiondata.id = index;
      for (let index = 0; index < this.table_data.length; index++) {
        if (index == questionTypeIndex) {
          this.editNewQuestionType = this.table_data[index].structure;
          this.editNewQuestionSigma = this.table_data[index].schema;
        }
      }
      this.editNewQuestionType = JSON.stringify(
        this.editNewQuestionType,
        null,
        2
      );
      this.editNewQuestionSigma = JSON.stringify(
        this.editNewQuestionSigma,
        null,
        2
      );
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/question-type/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.message != "data according to user") {
            this.addQuestionTypeHideButton = false;
          } else {
            this.addQuestionTypeHideButton = true;
            this.table_ = get_response.data.questionType;
            this.table_data = get_response.data.questionType;
          }
        });
    },
    updateQuestionType: function () {
      try {
        this.structure = JSON.parse(this.editNewQuestionType);
        this.schema = JSON.parse(this.editNewQuestionSigma);
      } catch (e) {
        alert("syntax error");
        return false;
      }
      this.editquestiondata.structure = this.structure;
      this.editquestiondata.schema = this.schema;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/question-type/edit",
          this.editquestiondata,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(response.data);

          this.gettabledata();
        });
      this.$root.$emit("bv::hide::modal", "modal-edit-questionType");
    },
    changetosigma() {
      var a = JSON.parse(this.addNewQuestionType.replace(/null/g, '""'));
      var b = this.addNewQuestionSigma;
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var nestedobject = {};
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          arrfinal = {};
          var newkey = "fieldname";
          arrfinal[newkey] = key;
          arrfinal["fieldType"] = "";
          arrfinal["show"] = true;

          arrfinal["values"] = [];
          arrfinal["nested"] = [];
          if (typeof value == "string") {
            // console.log("string", value);
            arrfinal["data"] = "";
          }
          if (typeof value == "object") {
            // console.log("object",value.length)
            if (value.length == 0) {
              arrfinal["data"] = [];
            } else {
              // console.log(value)
              for (let x = 0; x < value.length; x++) {
                // createobject = {};
                nestedobject = {};
                // console.log(Object.keys(value[x]).length);
                if (Object.keys(value[x]).length == 1) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    value[x]
                  )) {
                    // console.log("nestedkey",nestedkey);
                    // console.log("nestedvalue",nestedvalue);
                    createobject = {};
                    createobject["fieldname"] = nestedkey;
                    createobject["fieldType"] = "";
                    createobject["show"] = true;
                    // createobject["type"] = "";
                    createobject["values"] = [];
                    createobject["data"] = "";
                    // createobject["data"] = "";
                    // nestedobject[nestedkey] = createobject;
                    // console.log(nestedobject)
                    // if (Object.keys(value[x]).length) {
                    arrfinal["nested"].push(createobject);
                    // }

                    // console.log(arrfinal);
                    // var y = arrfinal["nested"].push(createobject);
                    // console.log(y);
                    // return "done";
                  }
                }
                if (Object.keys(value[x]).length > 1) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    value[x]
                  )) {
                    createobject = {};
                    createobject["fieldname"] = nestedkey;
                    createobject["fieldType"] = "";
                    createobject["show"] = true;
                    createobject["values"] = [];
                    createobject["data"] = "";
                    nestedobject[nestedkey] = createobject;
                  }

                  arrfinal["nested"].push(nestedobject);
                }
              }
            }
          }
          pushallobject.push(arrfinal);
        }

      }

      b = pushallobject;
      this.addNewQuestionSigma = b;
      this.addNewQuestionSigma = JSON.stringify(
        this.addNewQuestionSigma,
        null,
        2
      );
    },

    submitjsonmodel() {
      try {
        this.structure = JSON.parse(this.addNewQuestionType);
        this.schema = JSON.parse(this.addNewQuestionSigma);
      } catch (e) {
        alert("syntax error");
        return false;
      }
      const arr = [];
      arr.push(this.structure);
      arr.push(this.schema);
      axios
        .post(process.env.VUE_APP_API_URL + "/question-type/add", arr, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          this.gettabledata();
        });
      this.$root.$emit("bv::hide::modal", "modal-xl");
      this.structure = JSON.stringify(this.addNewQuestionType);
      this.schema = JSON.stringify(this.addNewQuestionSigma);
    },
    ImageRendererItems(data) {
      // console.log("in images");
      // console.log(this.optionindex);
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            this.multi_img = data.location;
          })
          .catch((err) => console.error(err));
      }
    },
    closemodel() {
      this.$root.$emit("bv::hide::modal", "modal-xl");
    },
    closeImageUploadmodel() {
      this.$root.$emit("bv::hide::modal", "upload-img-model");
    },
    closeEditModel() {
      this.$root.$emit("bv::hide::modal", "modal-edit-questionType");
    },
    JsontoStringify() {
      this.addNewQuestionType = JSON.stringify(
        this.addNewQuestionType,
        null,
        2
      );
    },
  },
};
</script>


<style>
.image-container {
  position: relative;
  width: 120px;
  height: 70px;
  overflow: hidden;
}

.preview-image {
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.3s ease;
}

.preview-image:hover {
  transform: scale(1.1);
  /* Adjust the scale value as needed */
}

.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-icon {
  width: 24px;
  /* Adjust the size of the icon */
  height: 24px;
}

.image-container:hover .icon-overlay {
  opacity: 1;
}
</style>
