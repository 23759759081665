<template>
  <div>
    <div v-if="Qtype === 'MCQs' || Qtype === 'Series' && levelData.length > 0">
      <component :key="levelKey" :is="Qtype === 'MCQs' ? 'PreviewQuestions' : 'SeriesPreviewQuestions'"
        :questions="levelData" :levelName="levelName" @showLevelCompletedModel="showLevelCompletedModel" />
    </div>

    <b-modal id="previewFullTopicQuestions" ref="previewFullTopicQuestions" hide-footer size="lg" centered
      @hidden="resetModal">
      <template #modal-title>
        <div class="d-flex flex-row align-items-center">
          <span>Level Completed</span>
        </div>
      </template>


      <div class="modal_content">
        <div>
          <template>
            <div class="level-container">
              <div class="level-info">
                <div style="width: 150px; height: 150px;">
                  <lottie :options="confettiOptions"></lottie>
                </div>
                <div>
                  <span class="level-text">Level {{ levelName }} is completed </span>
                </div>
              </div>
              <div class="button-container">
                <button class="button button-previous" :class="{ 'disabled': currentLevel < 0 }"
                  @click="handlePreviousLevel()">
                  <span class="arrow">←</span> Previous Level
                </button>
                <button class="button button-next"
                  :class="{ 'disabled': currentLevel === allLevelsQuestions.length - 1 }" @click="handleNextLevel()">
                  Next Level <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer">
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAlert,
  BSpinner,
} from "bootstrap-vue";
import blueButton from '../../../../public/blue-btn.png'
import purpleButton from '../../../../public/purple-btn.png'
import yellowButton from '../../../../public/yellow-btn.png'
import grayCheck from '../../../../public/grey_check.png'
import blueCheck from '../../../../public/blue_check.png'
import Confetti from '../../../../public/tick.json'
import amazoniteButton from '../../../../public/amazonite-btn.png';
import Lottie from 'vue-lottie';
import LottieView from "./LottieView.vue";
import axios from "axios";
import PreviewQuestions from "./PreviewQuestions.vue";
import SeriesPreviewQuestions from "./SeriesPreviewQuestions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    PreviewQuestions,
    SeriesPreviewQuestions,
    ToastificationContent,
    Lottie,
    yellowButton,
    blueButton,
    purpleButton,
    amazoniteButton,
    LottieView

  },
  data() {
    return {
      questionText: "",
      alertMessageToast: "All levels completed",
      currentQuestionIndex: 0,
      colors: ['#FDD835', '#7E57C2', '#42A5F5', '#66BB6A'],
      selectedOptionsArray: [],
      utterance: null,
      blueCheck,
      grayCheck,
      Confetti,
      confettiOptions: {
        loop: true,
        autoplay: true,
        animationData: Confetti
      },

      correctOption: false,
      selectedOption: null,
      selectedCheckBox: [],
      currentLevel: 0,
      levelData: [],
      Qtype: null,
      levelName: '',
      allLevelsQuestions: [],
      levelKey: 0,
      V1Qtype: false
    };

  },

  // watch: {

  //      currentLevel(newVal) {
  //       if (newVal === this.allLevelsQuestions.length - 1) {
  //         console.log('current reset')
  //         this.currentLevel = 0; 
  //       }
  //     },
  // },

  computed: {
  },
  created() {
    this.getAllLevelQuestions();
  },

  methods: {
    getAllLevelQuestions: function () {
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `/all-levels-questions/${this.$route.params.subcatId}`,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          if (get_response.data) {
            let filteredData = this.filterQuestions(get_response.data, ["MCQs", "Series"]);
            this.allLevelsQuestions = filteredData
            this.getCurrentLevel();
          }

        })
        .catch(function (error) {
          console.log('Error fetching data', error)
        });
    },



    filterQuestions(data, types) {
      return data.map(item => {
        const filteredQuestions = item.questions.filter(question =>
          types.includes(question.questionType)
        );
        if (filteredQuestions.length > 0) {
          return { ...item, questions: filteredQuestions };
        }
      }).filter(Boolean);
    },

    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },




    showLevelCompletedModel(value) {
      if (value && this.currentLevel < this.allLevelsQuestions.length - 1) {
        this.$refs.previewFullTopicQuestions.show();
      } else {
        this.showToast("success");
        this.currentLevel = 0;
        this.getCurrentLevel();
      }

    },

    handleNextLevel() {
      this.nextLevel();
      this.$nextTick(() => {
        if (this.Qtype === 'MCQs' || this.Qtype === 'Series') {
          const modalId = this.Qtype === 'MCQs' ? 'previewQuestions' : 'seriesPreviewQuestions';
          this.$bvModal.show(modalId);
        }
      });
    },
    handlePreviousLevel() {
      this.previousLevel();
      this.$nextTick(() => {
        if (this.Qtype === 'MCQs' || this.Qtype === 'Series') {
          const modalId = this.Qtype === 'MCQs' ? 'previewQuestions' : 'seriesPreviewQuestions';
          this.$bvModal.show(modalId);
        }

      });
    },

    previousLevel() {
      this.getCurrentLevel();
      this.$refs.previewFullTopicQuestions.hide();
      this.levelKey++;
    },
    nextLevel() {
      this.currentLevel++;
      this.getCurrentLevel();
      this.$refs.previewFullTopicQuestions.hide();
      this.levelKey++;
    },


    getCurrentLevel() {
      console.log('this.currentLevel', this.currentLevel)
      if (this.allLevelsQuestions.length > 0) {
        let data = this.allLevelsQuestions[this.currentLevel];
        if (data) {
          this.Qtype = data.questions[0].questionType;
          this.levelName = this.allLevelsQuestions[this.currentLevel].levelName
          let filteredData = this.filterQuestionsByType(data.questions, data.questions[0].questionType);
          if (filteredData) {
            this.levelData = filteredData
          } else {
            this.levelData = data.questions
          }

        } else {
          console.log('levelData is empty or not in the expected format');
        }
      } else {
        console.log('No levels available in allLevelsQuestions');
      }

    },

    filterQuestionsByType(data, questionType) {
      return data.filter(item => item.questionType === questionType);
    },

    prevQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.playAudio();
        this.selectedOption = null;
      }
      this.selectedOption = null;
      this.selectedCheckBox = [];
      this.selectedOptionsArray = [];
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.playAudio();
        this.selectedOption = null;
        this.selectedCheckBox = [];
        this.selectedOptionsArray = [];
      }
      else {
        if (levelName) {
          this.$emit('getCurrentLevel', true);
          this.currentQuestionIndex = 0;
        } else {
          if (this.$refs.previewFullTopicQuestions) {
            this.$refs.previewFullTopicQuestions.hide();
          }
        }



      }
    },
    resetModal() {
      this.currentQuestionIndex = 0;
      this.selectedOption = null;
      this.selectedCheckBox = [];
      this.selectedOptionsArray = [];
    },

  },
  props: {

  },

};
</script>

<style>
#add-questions-modal .card-body {
  padding: 0px;
}

.modal_content {
  flex: 1;
}

.curser-pointer {
  cursor: pointer;
}

ol {
  list-style-type: lower-alpha;
}

#previewFullTopicQuestions .modal-content {
  background-image: url('../../../../public/Scene_Default.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: white;
}

.question-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
}


.prev-icon img {
  width: 45px;
}

#previewFullTopicQuestions .modal-footer {
  padding: unset;
}

#previewFullTopicQuestions .modal-body {
  padding: unset;
}



input[type="checkbox"] {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
}

.checkbox-group span {
  font-size: 1.2em;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 80%;
  max-width: 80%;
  font-size: 33px;
}

.option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 50px !important;
  justify-content: center;
  align-items: center;
}

.two-images-option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  padding-bottom: 70px !important;
  justify-content: center;
  align-items: center;
}

.option-item {
  padding: 10px;
  border-radius: 5px;
  color: white;
  flex: 1 1 150px;
  text-align: center;
}


.two-images-option-item {
  width: 270px !important;
  height: 270px !important;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.two-images-option-item img {
  width: 180px;
}

.next-icon img {
  width: 45px;
}

#previewFullTopicQuestions .question-title {
  margin-bottom: 20px;
  text-align: center;
  align-self: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0D5CAB;
  display: inline-block;
  padding: 20px 25px;
  color: white;
  cursor: pointer;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 10px;

}

[dir] .modal-footer {
  border-top: 1px solid rgb(247 247 247 / 37%);
}

.circle-checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 75px;
  margin-bottom: 23px;
}

.checkbox-col {
  width: 65px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"] {
  flex: 0 0 15%;

}

.checkbox-title {
  flex: 1;
  text-align: center;
  overflow-wrap: break-word;
  text-align: center;
  word-break: break-word;
  padding-left: 65px;
  font-size: 25px;
  height: 100%;
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.level-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  font-family: Arial, sans-serif;
  justify-content: center;
  align-items: center;
}

/* Level Info */
.level-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 22px;
}

.tick-icon {
  width: 24px;
  height: 24px;
}

.level-text {

  font-weight: bold;
  color: #ffffff;
}

.button-container {
  display: flex;
  gap: 15px;
  margin-bottom: 10%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 200px;
  text-align: center;
}

.button:hover {
  transform: scale(1.05);
}

.button-previous {
  background: #7842bc;
  border-bottom: solid 6px #64309e;
}

.button-next {
  background: #f7b602;
  border-bottom: solid 6px #e4a501;
}


.arrow {
  font-size: 20px;
  font-weight: bolder;

}
</style>
