<template>
  <div>
    <div id="seriesPreviewQuestions" v-if="questions.length > 0">
      <div class="modal-content" style=" position: relative;" @shown="playAudio">
        <div class="question-container" style="position: relative;  justify-content: center; align-items: center;" v-if="currentQuestionIndex != null">
          <h3 class="question-title" @click="
            textToSpeak(questions[currentQuestionIndex].titleTts)
            ">{{ questions[currentQuestionIndex].title }}
          </h3>
          <div class="question-number">
            {{ currentQuestionIndex + 1 }} / {{ questions.length }}
          </div>
        </div>

         <template v-if="this.currentQuestionIndex != null">
            <template v-if="questions[this.currentQuestionIndex].plugin == 'DragAndDrop' || questions[this.currentQuestionIndex].plugin == 'MissingNumbers' || questions[this.currentQuestionIndex].plugin == 'MissingNumbersEvaluate'">
             <SeriesPreviewQuestionPluginDragAndDrop
               :currentQuestion="questions[this.currentQuestionIndex]"
               @get-question-response="handleQuestionResponse"
             ></SeriesPreviewQuestionPluginDragAndDrop>
           </template>
           
           <template v-if="questions[this.currentQuestionIndex].plugin == 'SentencesCompletionClick'">
             <SeriesPreviewQuestionPluginSentencesCompletionClick
               :currentQuestion="questions[this.currentQuestionIndex]"
               @get-question-response="handleQuestionResponse"
             ></SeriesPreviewQuestionPluginSentencesCompletionClick>
           </template>
           
           <template v-if="questions[this.currentQuestionIndex].plugin == 'MissingElements'">
             <SeriesPreviewQuestionPluginMissingElements
               :currentQuestion="questions[this.currentQuestionIndex]"
               @get-question-response="handleQuestionResponse"
             ></SeriesPreviewQuestionPluginMissingElements>
            </template>
           
           <template v-if="questions[this.currentQuestionIndex].plugin == 'EvaluateNumbers'">
             <SeriesPreviewQuestionPluginEvaluateNumbers
               :currentQuestion="questions[this.currentQuestionIndex]"
               @get-question-response="handleQuestionResponse"
             ></SeriesPreviewQuestionPluginEvaluateNumbers>
           </template>
         </template>

        <div v-if="showConfetti" style="position: absolute; top: 0%;">
          <lottie :options="confettiOptions"></lottie>
        </div>

      </div>
      <div class="modal-footer">
        <b-button variant="link" class="prev-icon" @click="prevQuestion" :disabled="currentQuestionIndex === 0">
          <img src="../../../../public/previous.png" alt="Previous" />
        </b-button>

        <b-button variant="link" class="next-icon" @click="nextQuestion"
          :disabled="currentQuestionIndex === questions.length - 1">
          <img src="../../../../public/next.png" alt="Next" />
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAlert,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import blueButton from '../../../../public/blue-btn.png'
import purpleButton from '../../../../public/purple-btn.png'
import yellowButton from '../../../../public/yellow-btn.png'
import grayCheck from '../../../../public/grey_check.png'
import blueCheck from '../../../../public/blue_check.png'
import Confetti from '../../../../public/Confetti.json'
import amazoniteButton from '../../../../public/amazonite-btn.png'
import { getDescriptionCode, getOptions } from "@/views/pages/helpers";
import { shortcodes } from "@/views/app/data/data";
import Lottie from 'vue-lottie';
import SeriesPreviewQuestionPluginDragAndDrop from "../components/question/SeriesPreviewQuestionPluginDragAndDrop.vue";
import SeriesPreviewQuestionPluginSentencesCompletionClick from "../components/question/SeriesPreviewQuestionPluginSentencesCompletionClick.vue";
import SeriesPreviewQuestionPluginMissingElements from "../components/question/SeriesPreviewQuestionPluginMissingElements";
import SeriesPreviewQuestionPluginEvaluateNumbers from "../components/question/SeriesPreviewQuestionPluginEvaluateNumbers";

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    ToastificationContent,
    Lottie,
    yellowButton,
    blueButton,
    purpleButton,
    amazoniteButton,
    SeriesPreviewQuestionPluginDragAndDrop,
    SeriesPreviewQuestionPluginSentencesCompletionClick,
    SeriesPreviewQuestionPluginMissingElements,
    SeriesPreviewQuestionPluginEvaluateNumbers,
  },
  data() {
    return {
      questionText: "",
      alertMessageToast: "",
      currentQuestionIndex: null,
      colors: ['#FDD835', '#7E57C2', '#42A5F5', '#66BB6A'],
      utterance: null,
      blueCheck,
      grayCheck,
      Confetti,
      confettiOptions: {
        loop: false,
        autoplay: true,
        animationData: Confetti
      },
      showConfetti: false,
      // seriesKey: 0,
      questions: [],
      loadingPage: false,
    };
  },
  computed: {
  },
  created(){
    this.getQuestions();
  },
  methods: {
    containsEnglish(text) {
      const englishPattern = /[a-zA-Z]/;
      return englishPattern.test(text);
    },

    handleQuestionResponse(data) {
      console.log('handleQuestionResponse data=', data);
      if (data.isCorrect) {
        this.textToSpeak('You are awesome');
        this.showConfetti = true;
        setTimeout(() => {
          this.showConfetti = false;
          this.nextQuestion();
        }, 1500);
      } else {
        this.textToSpeak('please try again');
      }
    },

    playAudio() {
      this.textToSpeak(this.questions[this.currentQuestionIndex].titleTts);
    },

    handleMultiOptionsSelection(option) {
      this.textToSpeak(option.tts);

      if (!this.selectedOptionsArray) {
        this.selectedOptionsArray = [];
      }

      let index;
      if (option.image) {
        index = this.selectedOptionsArray.findIndex(selected => selected.image === option.image);
      }
      else if (option.title) {
        index = this.selectedOptionsArray.findIndex(selected => selected.title === option.title);
      }

      if (index === -1) {
        this.selectedOptionsArray.push(option);
      } else {
        this.selectedOptionsArray.splice(index, 1);
      }
    },

    textToSpeak(text) {
      let speakText = text === null ? "" : text;
      if ("speechSynthesis" in window) {
        if (this.utterance) {
          window.speechSynthesis.cancel();
        }
        this.utterance = new SpeechSynthesisUtterance(speakText);
        window.speechSynthesis.speak(this.utterance);
      } else {
        alert("Sorry, your browser does not support text-to-speech.");
      }
    },

    isArithmeticExpression(title) {
      const regex = /^\d+$|^\d+\s*[-+*/]\s*\d+$/;

      return regex.test(title);
    },

    getWidth(totalOptions, length) {
      if (totalOptions == 2) {
        return '45%';
      }
      else if ((totalOptions == 3 || totalOptions == 4) && length < 10) {
        return '23%';
      }
      else { return '45%'; }
    },

    getImageWith(totalOptions) {

      if (totalOptions == 2) {
        return '250px'
      }
      else if (totalOptions == 3) {
        return '200px'
      }
      else if (totalOptions == 4) {
        return '150px'
      }
    },

    isCodeInArray(code, name) {
      const foundItem = shortcodes.find(item => item.name === name);
      if (foundItem) {
        const shortcodeArray = foundItem.shortcode || foundItem.shotcodes;
        if (shortcodeArray.includes(code)) {
          return true;
        }
      }
      return false;
    },

    // getQuestions() {
    //   if (this.questions.length > 0) {
    //     this.currentQuestionIndex = 0; // Ensure starting at the first question
    //     // this.seriesKey += 1;
    //     this.$refs.seriesPreviewQuestions.show(); // Open the modal
    //   }
    // },

    async getQuestions() {
      this.currentQuestionIndex = 0;
      console.log('getQuestions=', this.$route.params.levelId);
      this.loadingPage = true;
      const payload = {};
      payload.search = this.searchQuery;
      try {
          const res = await axios.get(
            process.env.VUE_APP_API_URL +
            `/questions/${this.$route.params.levelId}/Series`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          );
          if (res.data && res.data.questions.length > 0) {
              this.descriptionCount =
                  res.data.questions[0].descriptions.length;
              this.patternCount =
                  res.data.questions[0].patterns.length;
          }

          let data = this.insertHint(res.data.questions)
          if (data) {
              this.questions = data;
          } else {
              this.questions = res.data.questions
          }
          // this.questions = res.data.questions;
          this.originalQuestions = JSON.parse(
              JSON.stringify(res.data.questions)
          );

          this.loadingPage = false;
      } catch (error) {
          this.loadingPage = false;
          console.log('=error=',error)
      }
    },

    // generateShortCode(questions) {
    //   let currentQuestion = questions[this.currentQuestionIndex];
    //   let desc_code = getDescriptionCode(currentQuestion.descriptions);
    //   console.log('=currentQuestion=',currentQuestion, this.currentQuestionIndex)
    //   // let option_code = getOptions(currentQuestion)
    //   return desc_code + option_code;
    // },

    insertHint(data) {
      data.forEach((question) => {
        if (!question.hint) {
          this.$set(question, 'hint', {
            text: "",
            image: ""
          });
        }
        return question;
      });
    },

    getOptionBackground(optionIndex) {
      const images = [yellowButton, blueButton, amazoniteButton, purpleButton,];
      return images[optionIndex];
    },

    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },

    prevQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.playAudio();
      }
    },

    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.playAudio();
      }
      else {
        if (this.$refs.seriesPreviewQuestions) {
          this.$refs.seriesPreviewQuestions.hide();
        }
      }
    },

    resetModal() {
      this.currentQuestionIndex = 0;
    },
  },
  // props: ["questions"],
};
</script>

<style>
  #add-questions-modal .card-body {
  padding: 0px;
  }

  .curser-pointer {
  cursor: pointer;
  }

  ol {
  list-style-type: lower-alpha;
  }

  #seriesPreviewQuestions .modal-content {
  background-image: url('../../../../public/Scene_Default.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: white;
  }

  .question-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .question-number {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  }


  .prev-icon img {
  width: 45px;
  }

  #seriesPreviewQuestions .modal-footer {
  padding: unset;
  }

  #seriesPreviewQuestions .modal-body {
  padding: unset;
  }



  input[type="checkbox"] {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  }

  .checkbox-group span {
  font-size: 1.2em;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 80%;
  max-width: 80%;
  font-size: 33px;
  }

  .option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 50px !important;
  justify-content: center;
  align-items: center;
  }

  .two-images-option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  padding-bottom: 70px !important;
  justify-content: center;
  align-items: center;
  }

  .option-item {
  padding: 10px;
  border-radius: 5px;
  color: white;
  flex: 1 1 150px;
  text-align: center;
  }


  .two-images-option-item {
  width: 270px !important;
  height: 270px !important;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  }

  .two-images-option-item img {
  width: 180px;
  }

  .next-icon img {
  width: 45px;
  }

  #seriesPreviewQuestions .question-title {
  margin-bottom: 20px;
  text-align: center;
  align-self: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0D5CAB;
  display: inline-block;
  padding: 20px 25px;
  color: white;
  cursor: pointer;
  }

  .checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 10px;

  }

  [dir] .modal-footer {
  border-top: 1px solid rgb(247 247 247 / 37%);
  }

  .circle-checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 75px;
  margin-bottom: 23px;
  }

  .checkbox-col {
  width: 65px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  input[type="checkbox"] {
  flex: 0 0 15%;

  }

  .checkbox-title {
  flex: 1;
  text-align: center;
  overflow-wrap: break-word;
  text-align: center;
  word-break: break-word;
  padding-left: 65px;
  font-size: 25px;
  height: 100%;
  font-weight: bold;
  }

  .disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  }
</style>
