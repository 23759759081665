<template>
    <div class="pt-2 pb-3 evaluate-numbers">
      <!-- <div class="question-text">
        {{ currentQuestion }}
      </div> -->

      <template v-if="currentDescription">
          <div class="questions-description">
              <div class="description" v-if="currentDescription" @click="textToSpeak(currentDescription)">
                  {{ currentDescription }}
              </div>
          </div>
      </template>

      <div class="evaluate-numbers-container">
        <div class="question-data">
            <div class="d-flex justify-content-center">
                <div class="btn__expressions" v-if="this.currentQuestion._id">
                  <span v-for="(e, eIndex) in expressions" :key="eIndex" class="">
                      <span v-if="e.type == 'question'" @click="selectQuestion(e)">
                          <button v-if="e.expression != '__'" :class="`btn btn__white ${e.type} btn___${e.btn} ${currentQuestionIndex === e.optionsGroupsIndex ? 'vertical-line':''}`">
                              {{ e.expression }}
                          </button>
                          <button
                              v-else
                              :class="`btn btn__white ${e.type} ${currentQuestionIndex === e.optionsGroupsIndex ? 'vertical-line':''}`"
                          >&nbsp;</button>
                      </span>
                      <button v-else :class="`btn btn__white  ${e.type}`">
                          {{ e.expression }}
                      </button>
                  </span>
                </div>
            </div>
        
            <div class="btn__options">
              <div
                v-for="(o, oIndex) in options"
                :key="oIndex"
              >
                <button
                  :class="`btn btn__${o.btn} text-white`"
                  @click="handleOptionClick(o, oIndex)"
                >
                  {{ o.option }}
                </button>
              </div>
            </div>
        </div>
      </div>

    </div>
  </template>
  
<script>
import { BButton, BAlert, BSpinner } from "bootstrap-vue";
import _ from "lodash";
import * as Helpers from "@/Helpers";
  
export default {
    components: {
        BButton,
        BAlert,
        BSpinner,
    },
    data() {
        return {
            classes: ["blue", "green", "orange", "purple"],
            dragging: false,
            expressions: [],
            options: [],
            answers: [],
            Helpers,
            // currentPlaceholder: 0,
            currentQuestionIndex: 0,
            currentDescription: null,
        };
    },
    methods: {
        activeRemainingExpression() {
            const firstUnansweredQuestion = this.expressions.find(
                (exp) => exp.type === "question" && exp.expression === "__"
            );

            if(firstUnansweredQuestion != undefined){
                this.currentQuestionIndex = firstUnansweredQuestion.optionsGroupsIndex;
                this.getOptions();
            }
            console.log('firstUnansweredQuestion=',JSON.stringify(firstUnansweredQuestion));
        },
        selectQuestion(e) {
            console.log('selectQuestion e=', e);
            this.currentQuestionIndex = e.optionsGroupsIndex;
            this.getOptions();
        },
        getExpressions() {
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].expression) {
                const expression = this.currentQuestion.patterns[0].expression;
                const expressionArray = expression.split(",");

                let optionsGroupsCounter = 0;
                this.expressions = expressionArray.map((element, index) => {
                    const isQuestion = element === "__";
                    
                    return {
                        expression: element,
                        type: isQuestion ? "question" : "option",
                        optionsGroupsIndex: isQuestion ? optionsGroupsCounter++ : null,
                    };
                });

                const splitAnswers = this.Helpers.splitValueByPipeAccordingToExpression(this.expressions, this.currentQuestion.patterns[0].answer);
                console.log('splitAnswers=',splitAnswers);
                this.answers = splitAnswers;
            
                // this.currentPlaceholder = this.expressions.findIndex(
                //     (e) => e.type === "question" && !e.matched
                // );
            }
        },
        getOptions() {
            console.log('getOptions this.currentQuestionIndex=', this.currentQuestionIndex);
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].optionsGroups && this.currentQuestion.patterns[0].optionsGroups.length > 0) {
                const optionsTitle = this.currentQuestion.patterns[0].optionsGroups[this.currentQuestionIndex].title;
                const optionsArray = optionsTitle.split(",").map(option => option.trim());

                this.options = _.shuffle(optionsArray).map((option, index) => ({
                    option,
                    btn: this.classes[index % this.classes.length],
                }));
            }
        },
        getDescription(){
            if (this.currentQuestion.descriptions && this.currentQuestion.descriptions.length > 0) {
                const optionsTitle = this.currentQuestion.descriptions[0].title;
                this.currentDescription = optionsTitle ? optionsTitle : null;
            } else {
                this.currentDescription = null;
            }
        },

        handleOptionClick(option, optionIndex) {
            const questionIndex = this.expressions.findIndex(
                (e) => e.optionsGroupsIndex === this.currentQuestionIndex
            );
            
            if (questionIndex !== -1) {
                this.$set(this.expressions, questionIndex, {
                    ...this.expressions[questionIndex],
                    expression: option.option,
                    matched: true,
                    btn: option.btn,
                });

                // this.currentPlaceholder = this.expressions.findIndex(
                //     (e) => e.type === "question" && !e.matched
                // );

                const allFilled = this.expressions.every((e) => e.type !== "question" || e.matched);
                console.log('this.expressions=',this.expressions, allFilled)

                if (allFilled) {
                    const isCorrect = this.answers.some((answerSet) =>
                        answerSet.every((answer, index) => 
                            answer.expression === this.expressions[index].expression
                        )
                    );

                    const getEmit = { ...this.currentQuestion, isCorrect };

                    if (!isCorrect) {
                        setTimeout(() => {
                            this.getOptions();

                            this.expressions.forEach((e, index) => {
                                if (e.type === "question") {
                                    this.$set(this.expressions, index, {
                                        ...e,
                                        expression: "__",
                                        matched: false,
                                    });
                                }
                            });

                            // this.currentPlaceholder = this.expressions.findIndex(
                            //     (e) => e.type === "question" && !e.matched
                            // );

                            this.activeRemainingExpression();
                        }, 500);
                    } else {
                        this.currentQuestionIndex = 0;
                    }

                    console.log('getEmit=',getEmit)
                    this.$emit("get-question-response", getEmit);
                }
            }

            this.activeRemainingExpression();
        },
        textToSpeak(text) {
            let speakText = text === null ? "" : text;
            if ("speechSynthesis" in window) {
                if (this.utterance) {
                    window.speechSynthesis.cancel();
                }
                this.utterance = new SpeechSynthesisUtterance(speakText);
                window.speechSynthesis.speak(this.utterance);
            } else {
                alert("Sorry, your browser does not support text-to-speech.");
            }
        },

    },
    // watch: {
    //     currentQuestion() {
    //         this.getExpressions();
    //         this.getOptions();
    //         this.currentQuestionIndex = 0;
    //         alert('watch');
    //     },
    // },
    watch: {
        currentQuestion: {
            handler() {
                this.getExpressions();
                this.getOptions();
                this.getDescription();
                this.currentQuestionIndex = 0;
                // alert('watch');
            },
            deep: true
        }
    },
    mounted() {
        this.currentQuestionIndex = 0;
        this.getExpressions();
        this.getOptions();
        this.getDescription();
        // alert('mounted');
    },
    props: ["currentQuestion"],
};
</script>

<style>
.evaluate-numbers .evaluate-numbers-container {
    display: flex;
    gap: clamp(0.5rem, 2vw, 1rem);
    padding: 0 clamp(0.5rem, 2vw, 1rem);
    justify-content: center;
}
.evaluate-numbers .question-data {
    gap: clamp(0.5rem, 2vw, 1rem);
    display: flex;
    flex-direction: column;
}
.evaluate-numbers .questions-description-image {
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.evaluate-numbers .questions-description-image .image {
  padding: 10px;
}
.evaluate-numbers .btn__expressions {
    display: flex;
    /* flex-wrap: wrap; */
    gap: clamp(0.5rem, 2vw, 1rem);
    font-size: clamp(1.25rem, 2vw, 2rem);
    justify-content: center;
    align-items: center;
}
.evaluate-numbers .btn__expressions .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: clamp(1rem, 3vw, 2rem);
}
.evaluate-numbers .btn__expressions .btn.btn__white {
    /* background: #fff; */
}
.evaluate-numbers .btn__expressions .btn.btn__white.option {
    /* color: #ff0000; */
    color: #000;
    font-size: clamp(2.5rem, 2vw, 3rem);
    min-width: clamp(0.5rem, 6vw, 1rem);
}
.evaluate-numbers .btn__expressions .btn.btn__white.question {
    color: #000;
    background: #fff;
    border: solid 2px #0D5CAB;
    min-width: clamp(4rem, 6vw, 6rem);
}
.evaluate-numbers .btn__options {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}
.evaluate-numbers .btn__options .btn {
    min-width: clamp(4rem, 8vw, 5rem);
    min-height: clamp(4rem, 8vw, 5rem);

    display: flex;
    align-items: center;
    justify-content: center;
}
.evaluate-numbers .vertical-line {
    position: relative;
}
.evaluate-numbers .vertical-line:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: clamp(2rem, 8vw, 3rem);
    width: 2px;
    background: #000;
}
.evaluate-numbers .vertical-line:before,
.evaluate-numbers .blinking-cursor {
    animation: blink 1s infinite;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.evaluate-numbers .questions-description {
    display: flex;
    justify-content: center;
}
.evaluate-numbers .questions-description .description {
    background: #d0edfd;
    padding: 15px 20px;
    border-radius: 10px;
    color: #000;
    margin-bottom: 20px;
    cursor: pointer;
}
</style>