import _ from 'lodash';
// import moment from 'moment';
import moment from 'moment-timezone';

export const catchFunction = (err) => {
    console.log('catchFunction=',err)
    if (err.response) {
        const { data } = err.response;
        if (data?.errors) {
            const errorMessage = _.values(data.errors).some((arr) => arr.length > 0) ? _.first(_.flatten(_.values(data.errors))) : data.message;
            return errorMessage;
        } else {
            if(data.message){
                return data.message;
            }
            return 'An error occurred. Please try again later.';
        }
    } else if (err.request) {
        return 'Network error. Please check your internet connection.';
    } else {
        console.error('Error:', err.message);
        return 'An error occurred. Please try again later.';
    }
}

export const formatDateTime = (dateTime, formatStr = 'DD MMM YYYY, hh:mm A') => {
    if(!dateTime){
        return null;
    }
    const d = moment(dateTime).format(formatStr);
    return d
}

export const formatDateTimeFromISODate = (dateTime, formatStr = 'DD MMM YYYY, hh:mm A') => {
    if(!dateTime){
        return null;
    }
    const d = moment(Number(dateTime)).format(formatStr);
    return d
}

export const shortenFilename = (filename, maxLength) => {
    const extension = filename.split('.').pop();
    const basename = filename.substring(0, filename.lastIndexOf('.'));

    if (basename.length <= maxLength) {
        return filename;
    }

    const start = basename.slice(0, maxLength / 2);
    const end = basename.slice(-maxLength / 2);

    return `${start}...${end}.${extension}`;
}

export const hideObjectFromArrayByKey = (fields, keysToHide = [], hasPermission) => {
    if (!hasPermission) {
        return fields.filter(field => !keysToHide.includes(field.key));
    }
  
    return fields;
};

export const s3URL = () => {
    return "https://zape02.s3.ap-southeast-1.amazonaws.com/";
};

export const isTopicAccess = (topics, topicId) => {
    return topics.length > 0?topics.includes(topicId):false;
};

export const noPermission = (str = null) => {
    if(str){
        return `
            <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
                <div class="text-center">${str}</div>
            </div>
        `;
    }
    return `
        <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
            <div class="text-center">You do not have permission to access this page</div>
        </div>
    `;
};

export const gemsHistoryInGroup = (data) => {
    const grouped = _.groupBy(data, item => {
        return moment(item.createdAt).format('YYYY-MM-DD');
    });

    return Object.keys(grouped).map(date => {
        return {
            date,
            ago: moment(date).fromNow(),
            history: grouped[date]
        };
    });
};

// export const formatDate = (date) => {
//     if (date) {
//         const isoDateStr = date.includes('.') ? date.split('.')[0] + 'Z' : date;
        
//         const parsedDate = new Date(isoDateStr);

//         const options = {
//             day: 'numeric',
//             month: 'numeric',
//             year: 'numeric',
//             hour: '2-digit',
//             minute: '2-digit',
//             second: '2-digit',
//             timeZone: 'Asia/Karachi',
//             timeZoneName: 'short',
//         };

//         return new Intl.DateTimeFormat('en-GB', options).format(parsedDate);
//     } else {
//         return '-';
//     }
// };

// export const formatDate = (date) => {
//     if (date) {
//         // Create a Date object from the UTC date string
//         const isoDateStr = date.includes('.') ? date.split('.')[0] + 'Z' : date;
//         const parsedDate = new Date(isoDateStr);
        
//         // Extract the components
//         const day = String(parsedDate.getUTCDate()).padStart(2, '0'); // Get day and pad with 0
//         const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0'); // Get month (0-11) and pad with 0
//         const year = parsedDate.getUTCFullYear(); // Get full year
        
//         const hours = String(parsedDate.getUTCHours()).padStart(2, '0'); // Get hours in UTC
//         const minutes = String(parsedDate.getUTCMinutes()).padStart(2, '0'); // Get minutes in UTC
//         const seconds = String(parsedDate.getUTCSeconds()).padStart(2, '0'); // Get seconds in UTC

//         // Format the date as "DD/MM/YYYY, HH:MM:SS"
//         return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
//     } else {
//         return '-';
//     }
// };

export const formatDate = (date) => {
    if (date) {
        // Create a Date object from the UTC date string
        const isoDateStr = date.includes('.') ? date.split('.')[0] + 'Z' : date;
        const parsedDate = new Date(isoDateStr);
        
        // Extract the components
        const day = String(parsedDate.getUTCDate()).padStart(2, '0'); // Get day and pad with 0
        const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0'); // Get month (0-11) and pad with 0
        const year = parsedDate.getUTCFullYear(); // Get full year

        // Get hours, minutes, and seconds in UTC
        let hours = parsedDate.getUTCHours();
        const minutes = String(parsedDate.getUTCMinutes()).padStart(2, '0'); // Get minutes in UTC
        const seconds = String(parsedDate.getUTCSeconds()).padStart(2, '0'); // Get seconds in UTC

        // Convert to 12-hour format and determine AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours) : '12'; // Convert hour '0' to '12'
        
        // Format the date as "DD/MM/YYYY" and time as "H:MM AM/PM"
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        return `${formattedDate}, ${formattedTime}`;
    } else {
        return '-';
    }
};

export const changeDateFormat = (date, format = 'YYYY-MM-DD') => {
    const d = moment(date).format(format);
    return d
};

export const getThisMonthDates = () => {
    const timezone = 'Asia/Karachi';
    const startOfMonth = moment.tz(timezone).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment.tz(timezone).endOf('month').format('YYYY-MM-DD');

    return {
        startDate: new Date(startOfMonth),
        endDate: new Date(endOfMonth),
    };
};

export const getThisWeekDates = () => {
    const timezone = 'Asia/Karachi';
    const startOfWeek = moment.tz(timezone).startOf('week').format('YYYY-MM-DD');
    const endOfWeek = moment.tz(timezone).endOf('week').format('YYYY-MM-DD');

    return {
        startDate: new Date(startOfWeek),
        endDate: new Date(endOfWeek),
    };
};

export const getLastSevenDaysDates = () => {
    const timezone = 'Asia/Karachi';
    const startDate = moment.tz(timezone).subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
    const endDate = moment.tz(timezone).endOf('day').format('YYYY-MM-DD');

    return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
    };
};

export const getTodayDates = () => {
    const timezone = 'Asia/Karachi';
    const startDate = moment.tz(timezone).startOf('day').format('YYYY-MM-DD');
    const endDate = moment.tz(timezone).endOf('day').format('YYYY-MM-DD');

    return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
    };
};

export const jsonToArray = (jsonData) => {
    var data = [];
    if(jsonData != undefined){
        var arr = Object.entries(jsonData);
        if(arr.length > 0){
            arr.forEach((element, key) => {
                if(element.length > 0){
                    data.push({key: element[0], value: element[1]});
                }
            });
        }
    }
    return data;
}

// export const seriesQuestionsTextToJson = (content) => {
//     const questionSections = content.trim().split(/Question:\s*\d+/).filter(Boolean);

//     var questions = [];
//     questionSections.map(section => {
//         const lines = section.trim().split("\n").map(line => line.trim());
//         questions.push(lines);
//     });

//     return questions;
// }


export const seriesQuestionsTextToJson = (content) => {
    const questionSections = content.trim().split(/Question:\s*\d+/).filter(Boolean);
  
    const questions = questionSections.map((section) => {
      const lines = section.trim().split("\n").map(line => line.trim());
      const result = {
        questionType: 'Series',
        title: '',
        titleTts: '',
        plugin: '',
        descriptions: [],
        patterns: [],
        hint: {
            text: '',
            image: '',
        },
      };

      console.log('lines=',lines);
  
      lines.forEach((line) => {
        // const [key, value] = line.split(':').map((str) => str.trim());
        const [key, value] = line.split(/:(.+)/).map(str => str.trim());
        if (key && value !== undefined) {
        //   const [mainKey, index, subKey, subIndex, innerKey] = key.match(/([a-z]+)(\d+)?([A-Za-z]+)?(\d+)?([A-Za-z]+)?/)?.slice(1) || [];
        const [mainKey, index, subKey, subIndex, innerKey] = key.match(/([a-zA-Z]+)(\d+)?([a-zA-Z]+)?(\d+)?([a-zA-Z]*)?/)?.slice(1) || [];

          console.log('line key, value=',key, value, mainKey, index, subKey, subIndex, innerKey);
          if (mainKey === 'title') {
            result.title = value;
          } else if (mainKey === 'titleTts') {
            result.titleTts = value;
          } else if (mainKey === 'plugin') {
            result.plugin = value;
          } else if (mainKey === 'hintText') {
            result.hint.text = value;
          } else if (mainKey === 'hintImage') {
            result.hint.image = value;
          } else if (mainKey === 'descriptions') {
            if (!result.descriptions[index]) result.descriptions[index] = {};
            // if (!result.descriptions[index]) {
            //     result.descriptions[index] = { title: '', tts: '', image: null };
            // }
            result.descriptions[index][subKey] = value || null;
          } else if (mainKey === 'patterns') {
            if (!result.patterns[index]) result.patterns[index] = { optionsGroups: [] };
            if (subKey === 'optionsGroups') {
              if (!result.patterns[index].optionsGroups[subIndex]) result.patterns[index].optionsGroups[subIndex] = {};
              result.patterns[index].optionsGroups[subIndex][innerKey] = value || null;
            } else {
              result.patterns[index][subKey] = value || (subKey === 'image' ? [] : null);
            }
          }
        }
      });
  
      // Convert patterns and descriptions to the desired format
      return {
        ...result,
        descriptions: result.descriptions.map((desc) => ({
          ...desc,
          image: desc.image || null
        })),
        patterns: result.patterns.map((pattern) => ({
          ...pattern,
          optionsGroups: pattern.optionsGroups.map((group) => ({
            ...group,
            image: group.image || null
          }))
        }))
      };
    });
  
    return questions;
};

export const streakHistoryOrderByDate = (data) => {
    var history = [];
    if(data.length > 0){
        data.forEach(element => {
            let x = element;
            if(element.actionTime['$date'] != undefined){
                if (element.actionTime && element.actionTime['$date'] && element.actionTime['$date']['$numberLong']) {
                    const timestamp = parseInt(element.actionTime['$date']['$numberLong']);
                    const date = new Date(timestamp);
            
                    const options = {
                      day: '2-digit',
                      month: 'short', // Use 'short' for abbreviated month name
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true, // Use 12-hour format for AM/PM
                    };
            
                    x.date = date.toLocaleString('en-GB', options).replace(',', '');
                } else {
                    x.date = '-';
                }
            } else {
                x.date = formatDateTime(element.actionTime, 'DD MMM YYYY, hh:mm a');
            }
            history.push(x);
        });
    }
    // console.log('history=',history)

    if(history.length > 0){
        history.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            
            return dateB - dateA;
        });
    }

    return history; 
};

export const splitTextWithUnderscore = (text) => {
    const result = text.split(/(_+)/).filter(part => part.length > 0);
      
    // Map the result and replace underscore sequences with '?'
    return result.map(part => {
        return part.match(/^_+$/) ? '__' : part;  // Replace underscores with '?'
    });
};

/*
export const splitStringAccordingToExpression = (expressions, answerString) => {
    const result = [];
    let remainingString = answerString;

    expressions.forEach(part => {
        if (part.type === "option") {
            // Add option part directly to the result and remove from the remaining string
            result.push(part);
            if (remainingString.startsWith(part.expression)) {
                remainingString = remainingString.slice(part.expression.length);
            }
        } else if (part.type === "question") {
            // Find the next option expression in the remaining string to use as a boundary
            const nextOptionIndex = expressions
                .slice(result.length + 1) // Remaining expressions after the current position
                .find(nextPart => nextPart.type === "option")?.expression;

            const boundaryIndex = nextOptionIndex
                ? remainingString.indexOf(nextOptionIndex)
                : remainingString.length;

            // Extract only up to the boundary for the question part
            const questionValue = boundaryIndex !== -1
                ? remainingString.slice(0, boundaryIndex).trim()
                : remainingString.trim();

            result.push({ expression: questionValue, type: "question" });
            remainingString = remainingString.slice(questionValue.length);
        }
    });

    return result;
};
*/

export const parseStringByUnderscore = (str) => {
    const parts = str.split(/(__)/g);
    const result = [];
    
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] === '__') {
            result.push({ expression: parts[i], type: 'question' });
        } else {
            result.push({ expression: parts[i], type: 'option' });
        }
    }
    
    // return result.filter(item => item.expression.trim() !== "");
    return result;
}

export const splitStr1AndStr2 = (str, staticArray) => {
    let result = [];
    let lastIndex = 0;
  
    for (let i = 0; i < staticArray.length; i++) {
      const { expression, type } = staticArray[i];
  
      if (expression === '__') {
        // Find the start of the next expression
        const nextExpressionIndex = staticArray.findIndex((item, index) => index > i && item.expression !== '__');
        
        if (nextExpressionIndex !== -1) {
          const nextExpression = staticArray[nextExpressionIndex].expression;
          const nextExpressionStart = str.indexOf(nextExpression, lastIndex);
          const substring = str.slice(lastIndex, nextExpressionStart);
          const sp = substring.trim().split('|');
          console.log('=sp1=',sp);
          if(sp.length > 0 && sp[0]){
            result.push({ type: 'question', expression: sp });
          }
          lastIndex = nextExpressionStart; // Move lastIndex to the start of the next expression
        } else {
          // If no next expression exists, take the rest of the string
          const substring = str.slice(lastIndex);
          const sp = substring.trim().split('|');
          console.log('=sp2=',sp);
          if(sp.length > 0){
            result.push({ type: 'question', expression: sp });
          }
          lastIndex = str.length; // Move lastIndex to the end of the string
        }
      } else {
        // Handle regular expressions
        if(expression){
            result.push({ type, expression: expression });
        }
        lastIndex += expression.length; // Update lastIndex for the length of the expression
      }
    }
  
    // Handle any remaining string that wasn't captured
    if (lastIndex < str.length) {
        const sp = str.slice(lastIndex).trim().split('|');
        console.log('=sp3=',sp);
        if(sp.length > 0){
            result.push({ type: 'question', expression: sp });
        }
    }
  
    return result;
  };
  

export const splitStringAccordingToExpression = (expressions, answerString) => {
    const result = [];
    let remainingString = answerString.trim();

    expressions.forEach((part, index) => {
        if (part.type === "option") {
            result.push(part);
        } else if (part.type === "question") {
            const previousOption = index > 0 ? expressions[index - 1].expression : '';
            const nextOptionExpression = expressions
                .slice(index + 1)
                .find(nextPart => nextPart.type === "option")?.expression;

            // console.log('remainingString=', remainingString);
            console.log('previousOption=', previousOption);
            console.log('nextOptionExpression=', nextOptionExpression);
            // console.log('extractedTextFromPreviousNextText=', extractedTextFromPreviousNextText(previousOption, nextOptionExpression, remainingString));

            if(previousOption == null && nextOptionExpression == null){
                console.log('empty=');
                // const possibleAnswers = extractedTextFromPreviousNextText(previousOption, nextOptionExpression, remainingString)
                // result.push({ expression: possibleAnswers, type: "question" });
            } else if(previousOption || nextOptionExpression){
                console.log('not empty=');
                const possibleAnswers = extractedTextFromPreviousNextText(previousOption, nextOptionExpression, remainingString)
                result.push({ expression: possibleAnswers, type: "question" });
            }
        }
    });

    return result;
};

export const extractedTextFromPreviousNextText = (previousOption, nextOptionExpression, answerString) => {
    // console.log('previousOption, nextOptionExpression===', previousOption, nextOptionExpression, answerString);
    const sanitizedPreviousOption = previousOption ? previousOption.trim() : '';
    const sanitizedNextOptionExpression = nextOptionExpression ? nextOptionExpression.trim() : '';

    const escapedNextOptionExpression = sanitizedNextOptionExpression.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');

    let regex;
    if (sanitizedPreviousOption && sanitizedNextOptionExpression) {
        regex = new RegExp(`${sanitizedPreviousOption}\\s*(.*?)\\s*${escapedNextOptionExpression}`, 's');
    } else if (sanitizedPreviousOption) {
        regex = new RegExp(`${sanitizedPreviousOption}\\s*(.*?)$`, 's');
    } else if (sanitizedNextOptionExpression) {
        regex = new RegExp(`^(.*?)\\s*${escapedNextOptionExpression}`, 's');
    }

    const match = regex ? answerString.match(regex) : null;

    if (match && match[1]) {
        return match[1].split('|');
    }

    return [];
};

// export const extractedTextFromPreviousNextText = (previousOption, nextOptionExpression, answerString) => {
//     const sanitizedPreviousOption = previousOption.trim();
//     const sanitizedNextOptionExpression = nextOptionExpression.trim();

//     const escapedNextOptionExpression = sanitizedNextOptionExpression.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');

//     const regex = new RegExp(`${sanitizedPreviousOption}\\s*(.*?)\\s*${escapedNextOptionExpression}`, 's');
//     const match = answerString.match(regex);

//     console.log('match=',match[1]);

//     if (match) {
//         return match[1].split('|');
//     }

//     return [];
// };

export const splitValueByCommaAccordingToExpression = (expressions, answersString) => {
    const result = [];
    const answers = answersString.split(",");

    expressions.forEach((item, index) => {
        result.push({
            expression: answers[index],
            type: item.type,
        })
    });

    return result;
};

export const splitValueByPipeAccordingToExpression = (expressions, answersString) => {
    const result = [];
    const pipeAnswers = answersString.split("|");
    console.log('pipeAnswers=',pipeAnswers);

    if(pipeAnswers.length > 0){
        pipeAnswers.forEach(answers => {
            console.log('answers=',answers);
            result.push(splitValueByCommaAccordingToExpression(expressions, answers));
        });
    }

    return result;
};

export const textToSpeak = (text) => {
    let speakText = text === null ? "" : text;
    if ("speechSynthesis" in window) {
        if (this.utterance) {
            window.speechSynthesis.cancel();
        }
        this.utterance = new SpeechSynthesisUtterance(speakText);
        window.speechSynthesis.speak(this.utterance);
    } else {
        alert("Sorry, your browser does not support text-to-speech.");
    }
};

export default {
    catchFunction,
    formatDateTime,
    shortenFilename,
    hideObjectFromArrayByKey,
    s3URL,
    isTopicAccess,
    noPermission,
    gemsHistoryInGroup,
    formatDate,
    changeDateFormat,
    getThisMonthDates,
    getThisWeekDates,
    getLastSevenDaysDates,
    getTodayDates,
    jsonToArray,
    seriesQuestionsTextToJson,
    streakHistoryOrderByDate,
    streakHistoryOrderByDate,
    splitTextWithUnderscore,
    splitStringAccordingToExpression,
    splitValueByCommaAccordingToExpression,
    textToSpeak,
    parseStringByUnderscore,
    splitStr1AndStr2,
}