<template>
    <div>
        <b-modal id="user-current-plan-modal" hide-footer size="md" centered title="Edit Plan" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>

                <p class="mb-0 ">Billing Cycle</p>
                <v-select 
                        class="w-100 mb-1"
                        v-model="record.billing_cycle"
                        placeholder="Select Billing Cycle"
                        :clearable="true"
                        :reduce="(option) => option.name"
                        :options="get_billing_cycles"
                        label="label"
                        @input="changeBillingCycleDropdown"
                    >
                    <template v-slot:option="option">
                        {{ option.label }}
                    </template>
                </v-select>

                <p class="mb-0 ">Plans</p>
                <v-select 
                        class="w-100 mb-1"
                        v-model="record.plan_id"
                        placeholder="Select Plan"
                        :clearable="false"
                        :reduce="(option) => option._id"
                        :options="filteredPlans"
                        label="name"
                    >
                    <template v-slot:option="option">
                        {{ option.name }} - {{ option.price }}
                    </template>
                </v-select>

                <p class="mb-0 ">Note</p>
                <b-form-textarea
                    class="mb-1"
                    v-model="record.note"
                    placeholder="Note"
                    rows="5"
                ></b-form-textarea>

                <div class="modal-footer px-0">
                    <b-button @click="saveBtn" :disabled="loading || selected_user.plan_id == record.plan_id" variant="primary">
                        <b-spinner v-if="loading" small />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
export default {
    components: {
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                billing_cycle: '',
                plan_id: '',
                note: '',
            },
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        filteredPlans() {
            if (!this.record.billing_cycle) {
                return this.get_plans;
            }
            return this.get_plans.filter(plan => plan.billing_cycle === this.record.billing_cycle);
        },
    },
    watch: {
        selected_user: function (newVal) {
            if(!this.selected_user.current_plan){
                if(this.get_billing_cycles.length > 0){
                    const monthlyBillingCycle = _.find(this.get_billing_cycles, { name: '' });
                    this.record.billing_cycle = monthlyBillingCycle.name;
                    const freePlan = _.find(this.filteredPlans, { price: '0' });
                    this.record.current_plan = freePlan;
                    this.record.plan_id = freePlan._id;
                }
            } else {
                this.record.billing_cycle = this.selected_user.current_plan.billing_cycle;
                this.record.plan_id = this.selected_user.currentPlanId;
            }
        },
    },
    methods: {
        changeBillingCycleDropdown(){
            this.record.plan_id = '';
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
            this.record = {
                billing_cycle: '',
                plan_id: '',
                note: '',
            };
        },
        async saveBtn() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const payload = {
                    register_user_id: this.selected_user._id,
                    plan_id: this.record.plan_id,
                    note: this.record.note,
                }
                const resp = await axios.put(process.env.VUE_APP_API_URL + "/user/plan/update",
                    payload,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitPlanUpdated');
                this.$bvModal.hide("user-current-plan-modal");
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.note) {
                this.errorMsg = "Note field id required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "get_plans",
        "get_billing_cycles",
        "selected_user"
    ],
};
</script>
