import { render, staticRenderFns } from "./SeriesPreviewQuestionPluginMatchPatterns.vue?vue&type=template&id=707df34e&"
import script from "./SeriesPreviewQuestionPluginMatchPatterns.vue?vue&type=script&lang=js&"
export * from "./SeriesPreviewQuestionPluginMatchPatterns.vue?vue&type=script&lang=js&"
import style0 from "./SeriesPreviewQuestionPluginMatchPatterns.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports