<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-email-notifications>
            <span class="text-nowrap">Add Email Notification</span>
        </b-button>
        <b-modal id="add-email-notifications" hide-footer size="xl" centered title="Add Email Notification"
            @hidden="resetModal">
            <div class="modal_content">
                <div class="row">
                    <div class="col-6">
                        <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                            errorMsg }}</b-alert>
                        <p class="mb-0">Notification Type</p>
                        <b-form-select class="mb-1" v-model="record.notificationType"
                            :options="formattedNotificationTypes" value-field="value" text-field="text"
                            v-on:change="onChangeNotificationType" />
                        <p class="mb-0">Trigger Name</p>
                        <b-form-select class="mb-1" :disabled="record.notificationType == '' ? true : false"
                            v-model="record.whenTrigger"
                            :options="getNotificationTypeName(record.notificationType) ? activeProfileTriggerNames : nonActiveTriggerName" />
                        <p class="mb-0">Email Subject</p>
                        <b-form-textarea class="mb-1" v-model="record.subject" rows="1"
                            placeholder="Email Subject"></b-form-textarea>
                        <p class="mb-0">Email Body</p>
                        <b-form-textarea class="mb-1" v-model="record.body" rows="10"
                            placeholder="Email body"></b-form-textarea>
                    </div>
                    <div class="col-6">
                        <div v-html="record.body"></div>
                    </div>
                </div>

                <div class="modal-footer">
                    <b-button @click="addEmailNotification" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormSelect,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        BFormSelect,
        BFormTextarea,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            record: {
                whenTrigger: "",
                subject: "",
                body: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Zape Learning App</title>
                    </head>
                    <body style="margin: 0; font-family: Arial, sans-serif;">

                    <div style="text-align: center; background-color: #b6d4f9; padding: 20px;">
                        <img style="height:100px" src="https://zape02.s3.ap-southeast-1.amazonaws.com/Ketlox9YngKq4FPu5V4wf4yEbBtIoTI4r8JNrU2RmrblNuf7SglUU.gif?text=ZAPE+Learning+App" alt="Zape Learning App Logo">
                    </div>

                    <div style="text-align: center; ">
                        <img src="https://zape02.s3.ap-southeast-1.amazonaws.com/b06cZ4PB1fPS5kruqQWYp74l27fRWloaJs3qizghElJB7YhzHSEIEm22vMWE3pt625qV.png?text=Family+Learning" alt="Family Learning" style="width: 100%; ">
                    </div>

                    <div style="padding: 30px; text-align: center;">
                        <h2 style="color: #2b5f8d; margin-bottom: 10px;">Let’s not keep your child waiting!</h2>
                        <p style="color: #4a4a4a; margin-bottom: 20px;">
                        Your child’s learning journey has just started, and every day counts! <br>
                        Don’t miss out on the fun awaiting them.
                        </p>
                        <button style="padding: 15px 30px; background-color: #84d435; color: #fff; border: none; border-radius: 5px; font-size: 16px; cursor: pointer;">
                        Resume learning!
                        </button>
                    </div>

                    <div style="background-color: #f7f9fc; padding: 20px; text-align: center;">
                        <div style="display: inline-block; padding: 20px; border: 1px solid #ccc; border-radius: 10px; text-align: left; background: #fff;">
                        <div style="display: flex; align-items: center; margin-bottom: 10px;">
                            <img src="https://via.placeholder.com/50x50.png?text=User" alt="User" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px;">
                            <div>
                            <h3 style="margin: 0; font-size: 18px; color: #2b5f8d;">John Cartwright</h3>
                            <div style="background: #e6e6e6; height: 8px; width: 100%; border-radius: 4px; margin-top: 5px;">
                                <div style="width: 0%; height: 100%; background-color: #84d435; border-radius: 4px;"></div>
                            </div>
                            </div>
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                            <div style="display: inline-block; padding: 10px 20px; background: #fff3e0; color: #ff9800; font-weight: bold; border-radius: 5px;">
                            🔥 {{profileStreak}} days streak
                            </div>
                            <button style="padding: 10px 20px; background-color: #ffc107; color: #fff; border: none; border-radius: 5px; font-size: 14px; margin-left: 10px; cursor: pointer;">
                            Start your streak today!
                            </button>
                        </div>
                        </div>
                    </div>

                    <div style="background-color: #2b5f8d; color: #fff; padding: 20px; text-align: center;">
                        <p style="margin: 0; font-size: 14px;">Second Floor, 2-B CCA, Phase 5, DHA, Lahore, Pakistan</p>
                        <div style="margin: 10px 0;">
                        <a href="#" style="margin-right: 10px; color: #fff;">Facebook</a>
                        <a href="#" style="margin-right: 10px; color: #fff;">TikTok</a>
                        <a href="#" style="color: #fff;">YouTube</a>
                        </div>
                        <p style="margin: 0; font-size: 12px;">
                        If you do not wish to receive further emails from us, you can <a href="#" style="color: #84d435;">unsubscribe here</a>.
                        </p>
                    </div>

                    </body>
                    </html>
                    `,
                notificationType: "",
            },
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            activeProfileTriggerNames: [
                "Welcome Email",
                "Honor board (when 100% complete Grade)",
                "(Leaderboard/Rank Update)",
                "Badge Email (if he earned any badge)",
                "Rank Up and Badge Earned Email",
                "Daily Email",
                "Welcome Back"
            ],
            nonActiveTriggerName: [
                "Day 1",
                "Day 2",
                "Day 3",
                "Day 4",
                "Day 5",
                "Day 6",
                "Day 7",
            ],
            showFullScreen: false,
            showImage: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        formattedNotificationTypes() {
            return this.notificationTypes.map(item => ({
                value: item._id,
                text: item.name
            }));
        },
        // sanitizedEmailBody() {
        //     console.log('this.emailBody',this.emailBody);
        //     return DOMPurify.sanitize(this.emailBody);
        // },
    },
    methods: {
        onChangeNotificationType() {
            this.record.whenTrigger = '';
        },
        getNotificationTypeName() {
            const result = this.notificationTypes.find(item => item._id === this.record.notificationType);
            return !result || result.name.toLowerCase() === 'active';
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.record = {
                whenTrigger: "",
                subject: "",
                body: "",
                notificationType: "",
            };
            this.errorMsg = "";
        },
        async addEmailNotification() {
            this.errorMsg = "";
            let status = this.checkValidation();
            if (!status) {
                return;
            }
            this.loading = true;
            try {
                const resp = await axios.post(
                    process.env.VUE_APP_API_URL + "/email-notification",
                    this.record,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.$emit("fetchNotifications");
                    this.$bvModal.hide("add-email-notifications");
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg =
                        error.response.data.error || "An error occurred";
                } else if (error.request) {
                    this.errorMsg =
                        "No response received from server. Please check your network connection.";
                } else {
                    this.errorMsg =
                        "Error in setting up the request: " + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.notificationType.length) {
                this.errorMsg = "Notification Type required.";
                return;
            }

            if (!this.record.whenTrigger.length) {
                this.errorMsg = "Trigger name required.";
                return;
            }

            if (!this.record.subject.length) {
                this.errorMsg = "Email Subject required.";
                return;
            }

            if (!this.record.body.length) {
                this.errorMsg = "Email Body required.";
                return;
            }
            return "ok";
        },
    },
    props: ["manage", "token", "notificationTypes"],
};
</script>
