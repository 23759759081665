<template>
    <b-modal id="modal-xl-gallery-img" v-model="GalleryModelHideShow" hide-footer size="xl" centered title="Gallery"
        @hidden="onModalHidden">
        <div class="modal_content">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-start">
                        <input ref="gallery_image_upload" type="file"
                            accept="image/x-png,image/gif,image/jpeg, application/json, .json,image/svg+xml,.svg,.lottie" class="d-none"
                            @change="ImageRendererItems" />
                        <b-button variant="primary" :disabled="isLoading" class="mr-2 d-flex" @click="triggerFileInput">
                            <div class="d-flex">
                                <b-spinner v-if="isLoading" small style="margin-right: 5px;" label="Loading..." />
                                Upload
                            </div>
                        </b-button>
                        <b-form-select style="width: 100px;" v-model="selectImageType" class="mr-2"
                            :options="optionImageType"></b-form-select>
                        <b-form-input v-model="gallerySearchQuery" @input="filterGalleryImages"
                            placeholder="Search..." />
                    </div>
                </b-col>
            </b-row>
            <b-tabs style="height: 430px; overflow: scroll">
                <b-tab active @click="recent_image_add_in_gallery">
                    <template #title>
                        <span>Recently Uploaded </span>
                    </template>
                    <pagination class="m-t-10" :data="galleryImages" :limit=2
                        @pagination-change-page="recent_image_add_in_gallery">
                    </pagination>
                    <div>
                        <b-row class="match-height">
                            <b-col md="1" lg="1" style="padding: 8px; position: relative"
                                v-for="galleryimg in galleryImages.data" :key="galleryimg.img_id" v-lazy-container="{
                                    selector: 'img',
                                    error:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    loading:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                }">
                                <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]"
                                    @click="saveQuestionBackground(galleryimg.resize[selectImageType].img)">
                                    <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                                </div>
                                <div v-else @click="saveQuestionBackground(galleryimg.gallery_img)">
                                    <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                                        :data-src="galleryimg.gallery_img" alt="gallery" />
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
                <b-tab @click="recent_image_used_in_gallery">
                    <template #title>
                        <span>Recently Used</span>
                    </template>
                    <pagination class="m-t-10" :data="galleryImages" :limit=2
                        @pagination-change-page="recent_image_used_in_gallery">
                    </pagination>
                    <div>
                        <b-row class="match-height">
                            <b-col md="1" lg="1" style="padding: 10px; position: relative"
                                v-for="galleryimg in galleryImages.data" :key="galleryimg.img_id" v-lazy-container="{
                                    selector: 'img',
                                    error:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    loading:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                }">
                                <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]"
                                    @click="saveQuestionBackground(galleryimg.resize[selectImageType].img)">
                                    <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                                </div>
                                <div v-else @click="saveQuestionBackground(galleryimg.gallery_img)">
                                    <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                                        :data-src="galleryimg.gallery_img" alt="gallery" />
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>

                <b-tab @click="get_lottie_images">
                    <template #title>
                        <span>Lottie</span>
                    </template>

                    <!-- Pagination Component -->
                    <pagination class="m-t-10" :data="galleryImages" :limit="2"
                        @pagination-change-page="get_lottie_images">
                    </pagination>

                    <!-- Gallery Images Grid -->
                    <div>
                        <b-row class="match-height">
                            <b-col v-for="galleryimg in galleryImages.data" :key="galleryimg.img_id" md="1" lg="1"
                                style="padding: 10px; position: relative"
                                v-lazy-container="{ selector: 'img', error: errorImage, loading: loadingImage }">

                                <!-- Handling Images with Resized Versions -->

                                <!-- Handling Images without Resized Versions -->
                                <div @click="saveQuestionBackground(galleryimg.gallery_img)">
                                    <template v-if="getFileExtensionFromUrl(galleryimg.gallery_img) === 'json'"
                                        class="mb-2">
                                        <LottieView :url="galleryimg.gallery_img" :galleryLottie="true" />
                                    </template>

                                    <template v-else>
                                        <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                                            :data-src="galleryimg.gallery_img" alt="gallery" />
                                    </template>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>

            </b-tabs>
        </div>
    </b-modal>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BFormTextarea,
    BImg,
    BTh,
    BTd,
    BTbody,
    BOverlay,
    BTfoot,
    BTab,
    BTabs,
    BSpinner,
    BFormSelect,
} from "bootstrap-vue";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LottieView from "../question/LottieView.vue";
export default {
    components: {
        BCard,
        ToastificationContent,
        BRow,
        BOverlay,
        BImg,
        BCol,
        BFormInput,
        BFormTextarea,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BTab,
        BTabs,
        BSpinner,
        BFormSelect,
        LottieView
    },
    props: {
        props_hideShowModel: {
            type: Boolean,
            required: true,
        },
        keyForLottie: {
            type: Number,
            required: false,
        },
    },
    watch: {
        props_hideShowModel() {
            this.recent_image_add_in_gallery();
            this.GalleryModelHideShow = this.props_hideShowModel;
        },
    },
    data() {
        return {
            gallerySearchQuery: "",
            galleryImages: {},
            AllGalleryImages: [],
            GalleryModelHideShow: false,
            bearerToken: null,
            isLoading: false,
            optionImageType: [
                { 'value': 0, 'text': '100px' },
                { 'value': 1, 'text': '300px' },
                { 'value': 2, 'text': '500px' },
            ],
            selectImageType: 0,
            localKeyForLottie: this.keyForLottie,
            loadingImage: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            errorImage: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp'
        };
    },
    created() {
        // console.log(" this.keyForLottie = this.keyForLottie + 1;");

        this.bearerToken = "Bearer " + localStorage.getItem("_t");
    },
    methods: {
        triggerFileInput() {
            this.$refs['gallery_image_upload'].click();
        },

        getFileExtensionFromUrl(url) {
            if (!url) return '';
            const path = url.split('?')[0].split('#')[0];
            const extension = path.split('.').pop();

            if (extension === path) return '';

            return extension;
        },


        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                // this.localKeyForLottie += 1;
                // console.log('before gallery', this.localKeyForLottie);
                this.createGalleryImage(s3Url, file.name, ext)
            } catch (error) {
                this.isLoading = false;
                console.error('Error uploading file:', error);
            }
        },
        async createGalleryImage(s3Url, fileName, ext) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                    imageFormat: ext
                }
                await axios.post(process.env.VUE_APP_API_URL + '/gallery/image/create', galleryObj, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.$emit("saveCategoryImage", s3Url);
                this.isLoading = false;
                // this.localKeyForLottie += 1;
                // console.log('in gallery', this.localKeyForLottie);

                this.GalleryModelHideShow = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error saving image to gallery', error);
            }
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];
            if (file) {
                this.isLoading = true;
                try {
                    const fileType = file.type;
                    const fileExtension = this.getFileExtension(fileType);
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    });
                    const { url, key } = response.data;
                    const extention = fileExtension == 'json' ? 'lottie' : fileExtension
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    this.isLoading = false;
                    console.error('Error getting pre-signed URL:', error);
                }
            }
        },

        // getFileExtension(mimeType) {
        //     const mimeToExtension = {
        //         'image/jpeg': 'jpg',
        //         'image/png': 'png',
        //         'image/gif': 'gif',
        //         'application/json': 'json',
        //         '.json': 'json',
        //     };
        //     return mimeToExtension[mimeType] || 'png';
        // },

        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
            };
            return mimeToExtension[mimeType] || 'png';
        },

        onModalHidden() {
            this.$emit("modelClose", false);
        },
        saveQuestionBackground(params) {
            // this.localKeyForLottie += 1;
            this.$emit("saveCategoryImage", params);

            this.GalleryModelHideShow = false;
        },

        recent_image_used_in_gallery: function (page = 1, query) {
            (this.searchQuery = ""), (this.AllGalleryImages = []);
            (this.pageNewData = 0),
                (this.galleryImages = {}),
                query = this.gallerySearchQuery;
            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/recent-used/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.galleryImages = get_response.data;
                    this.tabStatus = 'recent'
                });
        },
        filterGalleryImages(e) {
            if (this.tabStatus === 'upload') {
                this.recent_image_add_in_gallery(1, e);
            } else {
                this.recent_image_used_in_gallery(1, e);
            }
        },
        recent_image_add_in_gallery: function (page = 1, query) {
            (this.searchQuery = ""), (this.AllGalleryImages = []);
            (this.pageNewData = 0),
                (this.galleryImages = {}),
                query = this.gallerySearchQuery;

            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/recent-add/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.galleryImages = get_response.data;
                    this.tabStatus = 'upload';
                });
        },

        get_lottie_images: function (page = 1, query) {
            (this.searchQuery = ""), (this.AllGalleryImages = []);
            (this.pageNewData = 0),
                (this.galleryImages = {}),
                query = this.gallerySearchQuery;
            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/lottie-images/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.galleryImages = get_response.data;
                });
        },

    },
};
</script>