<template>
  <b-modal id="modal-feedback" size="lg" centered :title="tittle">
    <img class="model_backgroundimage" :src="modelImage" />
    <div style="overflow: auto" class="modal_feedback_data">
      <div style="width: 100%; display: flex; flex-direction: row; margin-top: 10px;"
        v-for="(feedback, ind) in feedbackDatashow" :key="ind">
        <div v-if="ind == 0" style="width: 12%">
          <b-avatar size="70px" :src="feedback.avatar" />
        </div>
        <div v-if="ind > 0" style="width: 50%; background: white; border-radius: 10px; margin-left: 283px;">
          <div style="font-size: 20px; font-weight: 900; color: black">{{ feedback.userName }}</div>
          <div style="color: black">{{ feedback.reply }}</div>
          <span style="color: black; font-size: 15px; font-weight: 900">{{ feedback.replyDate }}</span>
          <span style="color: black; margin-left: 8px; font-size: 15px; font-weight: 900">{{ feedback.replyTime
            }}</span>
        </div>
        <div v-if="ind == 0" style="width: 50%; background: white; border-radius: 10px;">
          <div style="font-size: 20px; font-weight: 900; color: black">{{ feedback.userName }}</div>
          <div style="color: black">{{ feedback.reply }}</div>
          <span style="color: black; font-size: 15px; font-weight: 900">{{ feedback.replyDate }}</span>
          <span style="color: black; margin-left: 8px; font-size: 15px; font-weight: 900">{{ feedback.replyTime
            }}</span>
        </div>
        <div v-if="ind > 0" style="width: 8%; margin-left: 10px;">
          <b-avatar size="70px" :src="feedback.avatar" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
export default {
  components: {
    BAvatar
  },

  props: {
    modelImage: String,
    tittle: String,
    feedbackDatashow: {
      type: [Object, Array],
      required: false, 
    },
  },
};
</script>

<style scoped>
img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}
</style>